// ThreeJS and Third-party deps
import * as THREE from "three"
import * as dat from 'dat.gui'
import Stats from "three/examples/jsm/libs/stats.module"
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer"
import { SimplexNoise } from "three/examples/jsm/math/SimplexNoise"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"

// Core boilerplate code deps
import { createComposer, createRenderer, runApp, updateLoadingProgressBar } from "./core-utils"

// Shader imports
import WaterVertex from "./shaders/waterVertex.glsl"
import WaterFragment from "./shaders/waterFragment3.glsl"
import HeightmapFragment from "./shaders/heightmapFragment.glsl"
import SmoothFragment from "./shaders/smoothFragment.glsl"

global.THREE = THREE
THREE.ColorManagement.enabled = true

/**************************************************
 * 0. Tweakable parameters for the scene
 *************************************************/
const params = {
  // Ripple/Water simulation
  mouseSize: 60.0,
  viscosity: 0.985,
  waveHeight: 5.0,

  // Post-processing bloom
  bloomStrength: 2.5,
  bloomRadius: 0.2,
  bloomThreshold: 0.05,

  // Colors
  rippleColor: 0x330066,
  specularColor: 0xff00ff,
  hemiLightSkyColor: 0x822fdf,
  hemiLightGroundColor: 0x0fb9b1,
  directionalLightColor: 0xff00aa,
  ambientLightColor: 0x330046,
  backgroundColor: 0x050011,

  // Additional customization
  shininess: 40,
  opacity: 1.0,
  hemiLightIntensity: 1.0,
  directionalLightIntensity: 0.8,
  ambientLightIntensity: 0.2,

  cameraZoom: 1.0, // Adjusts camera's orthographic size
  smoothingIterations: 50, // Updated from the original 10 to 50
  showWireframe: false,

  // Automated "Click" Simulation
  autoClickEnabled: true,
  autoClickInterval: 1.0,
  autoClickRandomize: true,
  autoClickX: 0,
  autoClickY: 0,
  autoClickRadiusX: 200,
  autoClickRadiusY: 100
}

// Texture width for simulation
const FBO_WIDTH = 512
const FBO_HEIGHT = 256

const GEOM_WIDTH = window.innerWidth
const GEOM_HEIGHT = window.innerWidth / 2

const simplex = new SimplexNoise()

/**************************************************
 * 1. Initialize core threejs components
 *************************************************/
let scene = new THREE.Scene()
scene.background = new THREE.Color(params.backgroundColor)

let renderer = createRenderer({ antialias: true }, (_renderer) => {
  _renderer.outputColorSpace = THREE.SRGBColorSpace
})

let camera = new THREE.OrthographicCamera(
    window.innerWidth / -2,
    window.innerWidth / 2,
    window.innerHeight / 2,
    window.innerHeight / -2,
    -1000,
    1000
)

// Post-processing with Bloom
let bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    params.bloomStrength,
    params.bloomRadius,
    params.bloomThreshold
)

let composer = createComposer(renderer, scene, camera, (comp) => {
  comp.addPass(bloomPass)
})

/**************************************************
 * 2. Build the scene
 *************************************************/
let app = {
  lastAutoClickTime: 0, // track time for automated clicks

  async initScene() {
    await updateLoadingProgressBar(0.1)

    this.mouseCoords = new THREE.Vector2()
    this.raycaster = new THREE.Raycaster()
    this.mouseClicked = false

    // Remove manual click listener if not needed, but we can leave it for manual clicks too:
    this.container.style.touchAction = 'none'
    this.container.addEventListener('click', this.onPointerClick.bind(this))

    // Hemisphere light
    const hemiLight = new THREE.HemisphereLight(params.hemiLightSkyColor, params.hemiLightGroundColor, params.hemiLightIntensity)
    scene.add(hemiLight)

    // Directional light
    const mainLight = new THREE.DirectionalLight(params.directionalLightColor, params.directionalLightIntensity)
    mainLight.position.set(200, 300, 100)
    scene.add(mainLight)

    // Ambient light
    const ambientLight = new THREE.AmbientLight(params.ambientLightColor, params.ambientLightIntensity)
    scene.add(ambientLight)

    // Material parameters
    const baseColor = params.rippleColor
    const specularColor = params.specularColor

    const geometry = new THREE.PlaneGeometry(GEOM_WIDTH, GEOM_HEIGHT, FBO_WIDTH, FBO_HEIGHT)

    // Create a ShaderMaterial based on Phong
    const material = new THREE.ShaderMaterial({
      uniforms: THREE.UniformsUtils.merge([
        THREE.ShaderLib['phong'].uniforms,
        { 'heightmap': { value: null } }
      ]),
      vertexShader: WaterVertex,
      fragmentShader: WaterFragment,
      lights: true
    })

    material.color = new THREE.Color(baseColor)
    material.specular = new THREE.Color(specularColor)
    material.shininess = params.shininess
    material.uniforms['diffuse'].value = material.color
    material.uniforms['specular'].value = material.specular
    material.uniforms['shininess'].value = Math.max(material.shininess, 1e-4)
    material.uniforms['opacity'].value = params.opacity
    material.transparent = params.opacity < 1.0

    // Defines for the shader
    material.defines.FBO_WIDTH = FBO_WIDTH.toFixed(1)
    material.defines.FBO_HEIGHT = FBO_HEIGHT.toFixed(1)
    material.defines.GEOM_WIDTH = GEOM_WIDTH.toFixed(1)
    material.defines.GEOM_HEIGHT = GEOM_HEIGHT.toFixed(1)

    this.waterUniforms = material.uniforms

    this.waterMesh = new THREE.Mesh(geometry, material)
    this.waterMesh.matrixAutoUpdate = false
    this.waterMesh.updateMatrix()
    this.waterMesh.material.wireframe = params.showWireframe
    scene.add(this.waterMesh)

    // GPU computation setup
    this.gpuCompute = new GPUComputationRenderer(FBO_WIDTH, FBO_HEIGHT, renderer)

    if (renderer.capabilities.isWebGL2 === false) {
      this.gpuCompute.setDataType(THREE.HalfFloatType)
    }

    // Start flat - no initial noise
    const heightmap0 = this.gpuCompute.createTexture()
    this.fillTextureWithFlatSurface(heightmap0)

    this.heightmapVariable = this.gpuCompute.addVariable('heightmap', HeightmapFragment, heightmap0)
    this.gpuCompute.setVariableDependencies(this.heightmapVariable, [this.heightmapVariable])

    // Set uniforms for simulation
    this.heightmapVariable.material.uniforms['mousePos'] = { value: new THREE.Vector2(10000, 10000) }
    this.heightmapVariable.material.uniforms['mouseSize'] = { value: params.mouseSize }
    this.heightmapVariable.material.uniforms['viscosityConstant'] = { value: params.viscosity }
    this.heightmapVariable.material.uniforms['waveheightMultiplier'] = { value: params.waveHeight }
    this.heightmapVariable.material.defines.GEOM_WIDTH = GEOM_WIDTH.toFixed(1)
    this.heightmapVariable.material.defines.GEOM_HEIGHT = GEOM_HEIGHT.toFixed(1)

    const error = this.gpuCompute.init()
    if (error !== null) {
      console.error(error)
    }

    // Create smooth shader for water smoothing
    this.smoothShader = this.gpuCompute.createShaderMaterial(SmoothFragment, { smoothTexture: { value: null } })

    // GUI controls
    const gui = new dat.GUI()

    // Ripple settings folder
    const rippleFolder = gui.addFolder("Ripple Settings")
    rippleFolder.add(params, "mouseSize", 1.0, 100.0, 1.0).onChange((newVal) => {
      this.heightmapVariable.material.uniforms['mouseSize'].value = newVal
    })
    rippleFolder.add(params, "viscosity", 0.9, 0.999, 0.001).onChange((newVal) => {
      this.heightmapVariable.material.uniforms['viscosityConstant'].value = newVal
    })
    rippleFolder.add(params, "waveHeight", 0.1, 5.0, 0.1).onChange((newVal) => {
      this.heightmapVariable.material.uniforms['waveheightMultiplier'].value = newVal
    })
    rippleFolder.add(params, "smoothingIterations", 1, 50, 1).name("Smoothing Iterations")
    const buttonSmooth = { smoothWater: this.smoothWater.bind(this) }
    rippleFolder.add(buttonSmooth, 'smoothWater')

    // Material Folder
    const materialFolder = gui.addFolder("Material")
    materialFolder.addColor(params, 'rippleColor').name("Ripple Color").onChange((newVal) => {
      material.color.set(newVal)
      material.uniforms['diffuse'].value = material.color
    })
    materialFolder.addColor(params, 'specularColor').name("Specular Color").onChange((newVal) => {
      material.specular.set(newVal)
      material.uniforms['specular'].value = material.specular
    })
    materialFolder.add(params, 'shininess', 1, 200, 1).onChange((val) => {
      material.shininess = val
      material.uniforms['shininess'].value = Math.max(val, 1e-4)
    })
    materialFolder.add(params, 'opacity', 0.0, 1.0, 0.05).onChange((val) => {
      material.uniforms['opacity'].value = val
      material.transparent = val < 1.0
    })
    materialFolder.add(params, 'showWireframe').name('Wireframe').onChange((val) => {
      material.wireframe = val
    })

    // Lighting Folder
    const lightingFolder = gui.addFolder("Lighting")
    const hemiFolder = lightingFolder.addFolder("Hemisphere Light")
    hemiFolder.addColor(params, 'hemiLightSkyColor').name("Sky Color").onChange((newVal) => {
      hemiLight.color.set(newVal)
    })
    hemiFolder.addColor(params, 'hemiLightGroundColor').name("Ground Color").onChange((newVal) => {
      hemiLight.groundColor.set(newVal)
    })
    hemiFolder.add(params, 'hemiLightIntensity', 0.0, 2.0, 0.1).name("Intensity").onChange((val) => {
      hemiLight.intensity = val
    })

    const dirFolder = lightingFolder.addFolder("Directional Light")
    dirFolder.addColor(params, 'directionalLightColor').name("Color").onChange((newVal) => {
      mainLight.color.set(newVal)
    })
    dirFolder.add(params, 'directionalLightIntensity', 0.0, 2.0, 0.1).name("Intensity").onChange((val) => {
      mainLight.intensity = val
    })

    const ambFolder = lightingFolder.addFolder("Ambient Light")
    ambFolder.addColor(params, 'ambientLightColor').name("Color").onChange((newVal) => {
      ambientLight.color.set(newVal)
    })
    ambFolder.add(params, 'ambientLightIntensity', 0.0, 2.0, 0.1).name("Intensity").onChange((val) => {
      ambientLight.intensity = val
    })

    // Scene Folder
    const sceneFolder = gui.addFolder("Scene")
    sceneFolder.addColor(params, 'backgroundColor').name("Background").onChange((newVal) => {
      scene.background.set(newVal)
    })
    sceneFolder.add(params, 'cameraZoom', 0.5, 3.0, 0.1).name("Camera Zoom").onChange((val) => {
      this.adjustCameraZoom(val)
    })

    // Bloom folder
    let bloomFolder = gui.addFolder("Bloom")
    bloomFolder.add(params, "bloomStrength", 0, 5, 0.05).onChange((val) => {
      bloomPass.strength = Number(val)
    })
    bloomFolder.add(params, "bloomRadius", 0, 2, 0.05).onChange((val) => {
      bloomPass.radius = Number(val)
    })
    bloomFolder.add(params, "bloomThreshold", 0, 1, 0.05).onChange((val) => {
      bloomPass.threshold = Number(val)
    })

    // Automated Click Folder
    const autoFolder = gui.addFolder("Automated Click")
    autoFolder.add(params, 'autoClickEnabled').name("Enable Auto Click")
    autoFolder.add(params, 'autoClickInterval', 0.1, 10.0, 0.1).name("Auto Click Interval (s)")
    autoFolder.add(params, 'autoClickRandomize').name("Randomize Position")
    autoFolder.add(params, 'autoClickX', -GEOM_WIDTH/2, GEOM_WIDTH/2, 1).name("Fixed X Pos")
    autoFolder.add(params, 'autoClickY', -GEOM_HEIGHT/2, GEOM_HEIGHT/2, 1).name("Fixed Y Pos")
    autoFolder.add(params, 'autoClickRadiusX', 0, GEOM_WIDTH/2, 10).name("Random X Radius")
    autoFolder.add(params, 'autoClickRadiusY', 0, GEOM_HEIGHT/2, 10).name("Random Y Radius")

    // Stats for fps
    this.stats1 = new Stats()
    this.stats1.showPanel(0)
    this.stats1.domElement.style.cssText = "position:absolute;top:0px;left:0px;"
    this.container.appendChild(this.stats1.domElement)

    await updateLoadingProgressBar(1.0, 100)
  },

  fillTextureWithFlatSurface(texture) {
    const pixels = texture.image.data
    let p = 0
    for (let j = 0; j < FBO_HEIGHT; j++) {
      for (let i = 0; i < FBO_WIDTH; i++) {
        pixels[p + 0] = 0
        pixels[p + 1] = 0
        pixels[p + 2] = 0
        pixels[p + 3] = 1
        p += 4
      }
    }
  },

  smoothWater() {
    const currentRenderTarget = this.gpuCompute.getCurrentRenderTarget(this.heightmapVariable)
    const alternateRenderTarget = this.gpuCompute.getAlternateRenderTarget(this.heightmapVariable)

    for (let i = 0; i < params.smoothingIterations; i++) {
      this.smoothShader.uniforms['smoothTexture'].value = currentRenderTarget.texture
      this.gpuCompute.doRenderTarget(this.smoothShader, alternateRenderTarget)

      this.smoothShader.uniforms['smoothTexture'].value = alternateRenderTarget.texture
      this.gpuCompute.doRenderTarget(this.smoothShader, currentRenderTarget)
    }
  },

  onPointerClick(event) {
    this.setMouseCoords(event.clientX, event.clientY)
    this.mouseClicked = true
  },

  setMouseCoords(x, y) {
    this.mouseCoords.set((x / renderer.domElement.clientWidth) * 2 - 1, (y / renderer.domElement.clientHeight) * 2 - 1)
  },

  simulateClick() {
    // Simulate a click as if user clicked the water
    // Determine position
    let clickX, clickY
    if (params.autoClickRandomize) {
      clickX = (Math.random() * 2 - 1) * params.autoClickRadiusX
      clickY = (Math.random() * 2 - 1) * params.autoClickRadiusY
    } else {
      clickX = params.autoClickX
      clickY = params.autoClickY
    }

    // Convert to normalized device coords for raycast
    const ndcX = (clickX / (GEOM_WIDTH/2))
    const ndcY = (clickY / (GEOM_HEIGHT/2))
    this.mouseCoords.set(ndcX, ndcY)
    this.mouseClicked = true
  },

  resize() {
    this.adjustCameraZoom(params.cameraZoom)
  },

  adjustCameraZoom(zoomFactor) {
    camera.left = (window.innerWidth / -2) / zoomFactor
    camera.right = (window.innerWidth / 2) / zoomFactor
    camera.top = (window.innerHeight / 2) / zoomFactor
    camera.bottom = (window.innerHeight / -2) / zoomFactor
    camera.updateProjectionMatrix()
  },

  updateScene(interval, elapsed) {
    this.stats1.update()

    // Automated click logic
    if (params.autoClickEnabled) {
      // Check time since last auto click
      if (elapsed - this.lastAutoClickTime > params.autoClickInterval) {
        this.simulateClick()
        this.lastAutoClickTime = elapsed
      }
    }

    const hmUniforms = this.heightmapVariable.material.uniforms
    if (this.mouseClicked) {
      // Raycast to find intersection point
      this.raycaster.setFromCamera(this.mouseCoords, camera)
      const intersects = this.raycaster.intersectObject(this.waterMesh)
      if (intersects.length > 0) {
        const point = intersects[0].point
        hmUniforms['mousePos'].value.set(point.x, point.y)
      } else {
        hmUniforms['mousePos'].value.set(10000, 10000)
      }

      // Reset after one frame to ensure a single ripple
      this.mouseClicked = false
    } else {
      hmUniforms['mousePos'].value.set(10000, 10000)
    }

    // Compute new ripple state
    this.gpuCompute.compute()

    // Pass updated heightmap to the material
    this.waterUniforms['heightmap'].value = this.gpuCompute.getCurrentRenderTarget(this.heightmapVariable).texture
  }
}

/**************************************************
 * 3. Run the app
 *************************************************/
runApp(app, scene, renderer, camera, true, undefined, composer)